import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import PageHeader from "../components/pageSections/pageHeader"
import ParagraphTabsTextOnly from "../components/pageSections/paragraphTabsTextOnly"
import useScript from "../service/useScript"

const WhatsNew = (props) => {
const { data } = props
let pageTitle = ''
let pageInfo = {}
let components = []
let metaTags = []
if (data.allNodeTemplatePages && data.allNodeTemplatePages.edges) {
pageInfo = data.allNodeTemplatePages.edges[0]
pageTitle = pageInfo?.node?.title ? pageInfo.node.title : ''
components = pageInfo?.node?.relationships?.components ? pageInfo.node.relationships.components : []
metaTags = pageInfo?.node?.metatag ? pageInfo.node.metatag : []
}

useScript("/assets/js/latest-technology-slider.js")
return (
<Layout>
    <Meta 
    files={
    {
    js: [],
    css: [
    '/assets/css/homepage.css',
    '/assets/css/about-through-the-ages.css',
    '/assets/css/latest-technology-tab.css']
    }
    }
    tags={metaTags}
    />
    <main className="innerpage">
        <section className="section_bg pt-first-section pb-section about-ages latestTechnologyWrap">
            <div className="container">
                <div className="section-head">
                    <PageHeader
                        title={pageTitle}							
                    />
                </div>
            </div>
            <ParagraphTabsTextOnly
                content={components}
                />
        </section>
    </main>
</Layout>
)
}
export const query = graphql`
query whatsNewPage {
allNodeTemplatePages(
filter: {path: {alias: {regex: "/whats-new-at-p-d-hinduja-hospital$/"}}}
) {
edges {
node {
id
title
path {
alias
}
metatag {
    attributes {
        content
        href
        name
        property
        rel
    }
}
relationships {
components: field_component_type {
__typename
...ParagraphTabComponentWhatsNew 
}
}
}
}
}
}
fragment ParagraphTabComponentWhatsNew on paragraph__tab_components {
id
title: field_title
relationships {
components: field_components {
__typename
...ParagraphImageComponent
...ParagraphHTMLText
...ParagraphImageTextWhatsnew
}
}
}
fragment ParagraphImageTextWhatsnew on paragraph__image_text {
id
text: field_text_demo {
processed
}
image: field_image_demo {
alt
}
relationships {
image: field_image_demo {
id
uri {
    value
    url
}
}
mainimage: field_image {
id
uri {
    value
    url
}
}
}
}
`
export default WhatsNew