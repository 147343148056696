import React, { useState } from "react"

import ParagraphText from "../paragraphText"
import ParagraphInset from "../paragraphInset"
import ImageText from "../imageText"

import { slugify, objectDeepClone } from "../../../service/helper"

const ParagraphTabsTextOnly = (props) => {

    const { content } = props

    const tabTitles = content.map(el => {
        return {
            title: el.title,
            id: el.id
        }
    })
 
    const defaultImage =  "https://via.placeholder.com/481x278"

    let [activeTab, setActiveTab] = useState(content && content.length ? slugify(content[0].title) : '')

    return (
        <div className="container aboutContainer">
            <ul className="nav nav-tabs gallery-tabs" role="tablist">
                {
                    tabTitles.map((el, index) => {
                        return (
                            <li key={index} role="presentation" className="nav-item" style={{padding:"0px 8px"}}>
                                <a
                                    style={{padding:"15px 30px"}}
                                    onClick={() => setActiveTab(slugify(el.title))}
                                    className={`nav-link ${activeTab === slugify(el.title) ? 'active' : ''}`}
                                    data-toggle="tab" href={`#${slugify(el.title)}`} role="tab" aria-controls={`${slugify(el.title)}`} aria-selected="false">
                                    {el.title}</a>
                            </li>
                        )
                    })
                }
            </ul>
            <div className="container">
                <div className="tab-content mt-3">
                {
                    content.map((el, index) => {
                        const htmlArr = (el.relationships && el.relationships.components && el.relationships.components.length > 0 ) ? el.relationships.components : []
                       
                        if(htmlArr[0]['__typename'] === 'paragraph__html_text' && el.title !== "Latest  Technology ")
                        {
                            return (
                                <div key={index} className={`tab-pane ${activeTab === slugify(el.title) ? 'active' : ''}`} id={slugify(el.title)} role="tabpanel">
                                 <div className="card">
                                       {
                                        htmlArr.map((item,index) => <ParagraphText key={index} content={item}/>)                                   
                                       }
                                 </div> 
                                </div>
                            )
                        }else{
                            return (
                                <div key={index} className={`tab-pane ${activeTab === slugify(el.title) ? 'active' : ''}`} id={slugify(el.title)} role="tabpanel">
                                 <div className="card">
                                   <ImageText content= {htmlArr}/>
                                 </div> 
                                </div>
                            )
                        }
                   
                        
                            // <div key={index} className={`tab-pane ${activeTab === slugify(el.title) ? 'active' : ''}`} id={slugify(el.title)} role="tabpanel">
                            //     <div className="card">
                            //         <div className="row">

                            //                  {
                            //                     htmlArr.map((item, index) => {
                                                
                            //                         if (item['__typename'] === 'paragraph__html_text' && el.title !== "Latest  Technology ") {
                            //                             return (
                            //                                 <ParagraphText
                            //                                      key={index}
                            //                                     content={item}
                            //                                 />

                            //                             )

                            //                         }
                            //                     })

                            //                 } 
                                        
                            //         </div>

                            //         <div class="row text-center mb-4">
                                    
                            //         { 
                                    
                            //         components.map((el, index) => {
                                        

                            //             if (el['__typename'] === 'paragraph__image_text') { 
                                             
                            //                 return (
                                                 
                            //                      <ImageText   
                                                    
                            //                         key={index}
                                                    
                            //                         content={el}

                            //                     /> 
                            //                     )
                            //                 }

                            //             })
                            //         }

                            //         </div>

                            //     </div>
                            // </div>
                        
                       
                    })
                }
                </div>
            </div>
        </div>
            
    )
 
}

export default ParagraphTabsTextOnly
