import React,{useEffect,useState} from "react"

const ImageText = (props) => {
    
    const { content } = props

    const [text, setText] = useState([]);
    const [images, setImages] = useState([])

    const removeText = (i) => content.filter((_,index) => index === i)
    const getImages = (i) => content.filter((_,index) => index !== i)

    useEffect(() => {
    setText(removeText(0));
    setImages(getImages(0))
    }, [])


    return (
        <div className="latestTechnologyWrap">
       { text.length > 0 &&
            <div className="col-md-12" dangerouslySetInnerHTML={{__html : text[0]?.htmlText?.processed}}></div>
       }
        <div className="overflow-hidden">
            <div className="slick-slider">
            {
                images.map((ele,ind) => {
                    const img = ele.relationships?.mainimage?.uri?.url
                    const alt = ele.image.alt ;
                    return(
                        <div className="img_slide">
                            <figure>
                                <img src={img} alt={alt.length ? alt : `image${ind}`}/> 
                                <p className="img_overlay">
                                    <img src="assets/images/icons/icon_Virtual_tour.svg" alt="" />
                                    <span> Click Here 360<sup>o</sup> Virtual Tour</span>
                                </p>
                            </figure>
                            <div dangerouslySetInnerHTML={{__html :ele?.text?.processed }}/>
                            {/* <h2>Robotics</h2>
                            <p>World's most advanced Integrated DaVinci Xi Robotic System.</p> */}
                        </div>
                    )
                })
            }
            </div>
        </div>
         {/* <div className="gallery_container imageGalleryWrap">
                           <h2 className="section-heading text-center">Image Gallery</h2>
                           <div className='slider-for'>
                               {
                                   images.map((ele,ind) =>{
                                     const img = ele.relationships?.mainimage?.uri?.url
                                     const alt = ele.image.alt ;
                                       return(
                                        <div className="slideBox" key={ind}>
                                            <img src={img} alt={alt.length ? alt : `image${ind}`}/> 
                                        </div>
                                       )
                                   })
                               }
                           </div>
                           <div className='slider-nav'>
                               {
                                   images.map((ele,ind) =>{
                                    const img = ele.relationships?.image?.uri?.url
                                    const alt = ele.image.alt ;
                                    return(
                                        <div className="slideBox" key={ind}>
                                          <img src={img} alt={alt.length ? alt : `image${ind}`}/>
                                          <div dangerouslySetInnerHTML={{__html :ele?.text?.processed }}/>
                                        </div>
                                       )
                                   })
                               }
                           </div>
         </div> */}
        </div>
    )
}


export default ImageText
